// TODO: JSDoc for all methods
const keyCode = Object.freeze({
    SPACE: 32,
    ENTER: 13
});

const IMAGE_MODE_CAROUSEL = 'carousel';
const IMAGE_MODE_ZOOM = 'zoom';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Carousel').default>>} carousel
 * @typedef {{alt: string, url: string, absURL: string, title: string, index: number, zoomUrl: string}} ProductImage
 */

/**
 * @description Base ProductImages implementation
 * @param {ReturnType<typeof import('widgets/global/AccessibilityFocusTrapMixin').default>} AccessibilityFocusTrapMixin mixin
 * @returns {typeof ProductImages} ProductImages class
 */
export default function (AccessibilityFocusTrapMixin) {
    /**
     * @class ProductImages
     * @augments AccessibilityFocusTrapMixin
     * @classdesc Basic ProductImages Widget, manages two images carousels: thumbnails and images.
     * <br>Renders images from server response and update carousels states accordingly.
     * <br>Listens to carousel scroll/click events to coordinate consistent work of two carousels.
     * <br>Serves image `zoom` popup functionality using `photoswipe` as a library
     * @property {string} data-widget - Widget name `productImages`
     * @property {string} data-thumbnails-per-frame - Number of thumbnails fully visible per frame
     * @property {string} data-thumbnails-zoom-class - Class of the thumbnails carousel, when zoom is opened
     * @property {string} data-zoom-loop - Zoom carousel needs to be looped or not
     * @property {string} data-zoom-click-to-close-non-zoomable - Close or not zoom popup for not zoomable elements
     * @property {string} data-zoom-close-el-classes - Closable zoom popup classes. For ex. `item,caption,zoom-wrap,ui,top-bar`
     * @property {boolean} closeOnScroll - Close gallery on page scroll
     * @property {boolean} showHideOpacity - Animate background opacity and image scale
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="productImages"
     *     data-thumbnails-per-frame="4"
     * >
     *     <div
     *         class="b-product_gallery"
     *         data-ref="carouselInner"
     *     >
     *         <div
     *             class="b-product_gallery-thumbs"
     *             data-widget="carousel"
     *             id="imagesThumbnails"
     *             ....
     *             data-widget-event-pageclicked="onThumbnailCarouselPageClicked"
     *         >...</div>
     *         <div
     *             class="b-product_gallery-main b-product_slider"
     *             data-widget="carousel"
     *             id="imagesCarousel"
     *             ....
     *             data-widget-event-pagechanged="onImageCarouselPageChanged"
     *         >...</div>
     *     </div>
     *     <script type="template/mustache" data-ref="galleryTemplate">
     *         <div
     *             class="b-product_gallery"
     *             data-ref="carouselInner"
     *         >
     *             ... mustache carousels templates
     *         </div>
     *     </script>
     * </div>
     */
    class ProductImages extends AccessibilityFocusTrapMixin {
        prefs() {
            return {
                thumbnailsPerFrame: 4,
                thumbnailsZoomClass: 'm-zoomed-in',
                zoomLoop: false,
                zoomClickToCloseNonZoomable: false,
                zoomCloseElClasses: '',
                classesGlobalDialog: 'm-has_dialog',
                closeOnScroll: false,
                predefinedHeight: 1773,
                predefinedWidth: 1333,
                showHideOpacity: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.onDestroy(() => {
                if (this.gallery) {
                    this.gallery.destroy();
                    this.gallery = undefined;
                }
            });
        }

        /**
         * @param {{images: {large: Array<ProductImage>}, zoomImages: {zoom: Array<ProductImage>}}} product - product object
         */
        renderImages(product) {
            if (product.images && product.images.large) {
                product.images.large.forEach(element => {
                    const zoomImage = product.zoomImages.zoom && product.zoomImages.zoom[element.index];
                    element.zoomUrl = zoomImage && zoomImage.url;
                });

                this.render('galleryTemplate', { images: product.images }, this.ref('carouselInner')).then(() => {
                    this.update();
                });
            }
        }

        /**
         * @param {RefElement} el - event source element
         * @param {number} page - current page number
         */
        onImageCarouselPageChanged(el, page) {
            this.getById('imagesThumbnails', (/** @type {carousel} */ carousel) => {
                if (this.imageMode !== IMAGE_MODE_ZOOM) {
                    carousel.markCurrentPage(page).scrollIntoView();
                }
            });
        }

        /**
         * @param {RefElement} el - event source element
         * @param {number} page - current page number
         */
        onThumbnailCarouselPageClicked(el, page) {
            this.getById('imagesCarousel', (/** @type {carousel} */ carousel) => carousel.scrollToPage(page));

            if (this.imageMode === IMAGE_MODE_ZOOM && this.gallery) {
                this.gallery.goTo(page);
            }

            this.getById('imagesThumbnails', (/** @type {carousel} */ carousel) => {
                carousel.markCurrentPage(page).scrollIntoView();
            });
        }

        update() {
            this.getById('imagesThumbnails', (/** @type {carousel} */ carousel) => carousel
                .update()
                .scrollToPage(0)
                .markCurrentPage(0));

            this.getById('imagesCarousel', (/** @type {carousel} */ carousel) => carousel
                .update()
                .scrollToPage(0));
        }

        /**
         * @description Get URL from data-original-src attribute.
         *  Load original image by URL. Get original size.
         *  The image element should have data-original-src attribute with original image URL.
         * @returns {InstanceType <typeof Promise>} - return new Promise
         */
        getOriginalImageSize() {
            return new Promise((resolve) => {
                const imgCarousel = this.getById('imagesCarousel', (/** @type {carousel} */ carousel) => carousel);
                const predefinedSize = {
                    originalHeight: this.prefs().predefinedHeight,
                    originalWidth: this.prefs().predefinedWidth
                };
                if (!imgCarousel) {
                    resolve(predefinedSize);
                } else {
                    const carouselImages = imgCarousel.getImages();
                    const firstImage = carouselImages && carouselImages[0];
                    if (firstImage.dataset.originalSrc) {
                        const img = new Image();
                        img.addEventListener('load', () => resolve({ originalHeight: img.height, originalWidth: img.width }));
                        img.addEventListener('error', () => resolve(predefinedSize));
                        img.src = firstImage.dataset.originalSrc;
                    } else {
                        resolve(predefinedSize);
                    }
                }
            });
        }

        /**
         * @description Add focus trap functionality in the opened zoom dialog to prevent background elements focusing
         */
        addFocusTrap() {
            this.backFocusElement = /** @type {HTMLElement} */(document.activeElement);
            this.addFocusTraps();
            this.afterShowModal();
        }

        /**
         * @description Does all the work to init photoswipe and show it
         * @param {typeof import('photoswipe')} PhotoSwipe - PhotoSwipe library class
         * @param {typeof import('photoswipe/dist/photoswipe-ui-default')} PhotoSwipeUI - PhotoSwipeUI_Default library class
         * @param {{originalWidth: string, originalHeight:string}} originalImageSize Object with original image width and height
         */
        initAndShowZoom(PhotoSwipe, PhotoSwipeUI, { originalWidth, originalHeight }) {
            const pswpElement = document.querySelectorAll('.pswp')[0];
            const imgCarousel = this.getById('imagesCarousel', (/** @type {carousel} */ carousel) => carousel);
            const thumbnailsCarousel = this.getById('imagesThumbnails', (/** @type {carousel} */ thumbnails) => thumbnails);

            if (!imgCarousel || !(pswpElement instanceof HTMLElement)) {
                return;
            }

            const carouselImages = imgCarousel.getImages();

            if (!carouselImages) {
                return;
            }

            pswpElement.setAttribute('data-tau', 'zoom_dialog');

            const items = Array.from(carouselImages).map(element => {
                return {
                    src: element.dataset.originalSrc,
                    w: originalWidth || element.naturalWidth,
                    h: originalHeight || element.naturalHeight
                };
            });

            const prefs = this.prefs();

            const options = {
                index: imgCarousel.getCurrentPageIndex(),
                loop: prefs.zoomLoop,
                history: false,
                clickToCloseNonZoomable: prefs.zoomClickToCloseNonZoomable,
                closeElClasses: prefs.zoomCloseElClasses.split(','),
                closeOnScroll: prefs.closeOnScroll,
                showHideOpacity: prefs.showHideOpacity
            };

            const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI, items, options);

            gallery.listen('close', () => this.onZoomClosed());

            gallery.listen('beforeChange', () => {
                const currentPage = gallery.getCurrentIndex();

                imgCarousel.scrollToPage(currentPage);

                if (thumbnailsCarousel) {
                    thumbnailsCarousel.markCurrentPage(currentPage).scrollIntoView();
                }
            });

            gallery.init();

            this.gallery = gallery;
            this.imageMode = IMAGE_MODE_ZOOM;

            if (thumbnailsCarousel) {
                thumbnailsCarousel.toggleZoomState(true);
            }

            this.addGlobalDialogClass();
            this.addFocusTrap();
        }

        /**
         * @description Click handler on image from large images carousel
         * @returns {Promise} - Promise that fulfills when all of the promises passed as an iterable have been fulfilled
         */
        loadPhotoswipeDependencies() {
            return Promise.all([
                import(/* webpackChunkName: 'photoswipe' */'photoswipe'),
                import(/* webpackChunkName: 'photoswipe' */'photoswipe/dist/photoswipe-ui-default.js'),
                this.getOriginalImageSize()
            ]);
        }

        /**
         * @description Click handler on image from large images carousel
         */
        onImageCarouselPageClicked() {
            this.zoom();
        }

        /**
         * @description Click handler for "zoom" icon
         */
        openZoom() {
            this.zoom();
        }

        /**
         * @description Generic method to open zoom popup
         */
        zoom() {
            this.loadPhotoswipeDependencies().then(([PhotoSwipe, PhotoSwipeUI, originalImageSize]) => {
                this.initAndShowZoom(PhotoSwipe.default, PhotoSwipeUI.default, originalImageSize);
            });
        }

        /**
         * @description "Close" photoswipe popup icon click handler
         */
        closeZoom() {
            if (this.gallery) {
                this.gallery.close();
            }

            const pswpElement = document.querySelectorAll('.pswp')[0];

            if (!(pswpElement instanceof HTMLElement)) {
                return;
            }

            pswpElement.removeAttribute('data-tau');
        }

        /**
         * @description Zoom Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        closeZoomKeydown(_, event) {
            if (event.keyCode === keyCode.ENTER || event.keyCode === keyCode.SPACE) {
                event.stopPropagation();
                event.preventDefault();
                this.closeZoom();
            }
        }

        /**
         * @description Sets image mode / do some DOM modifications after zoom closed
         */
        onZoomClosed() {
            this.getById('imagesThumbnails', (/** @type {carousel} */thumbnails) => thumbnails.toggleZoomState(false));
            this.imageMode = IMAGE_MODE_CAROUSEL;
            this.removeGlobalDialogClass();

            if (this.backFocusElement) {
                this.backFocusElement.focus();
                this.backFocusElement = null;
            }
        }

        /**
         * @description Add Global Dialog Class
         * @returns {void}
         */
        addGlobalDialogClass() {
            const html = this.ref('html');

            if (!html.hasClass(this.prefs().classesGlobalDialog)) {
                html.addClass(this.prefs().classesGlobalDialog);
            }
        }

        /**
         * @description Remove Global Dialog Class
         * @returns {void}
         */
        removeGlobalDialogClass() {
            this.ref('html').removeClass(this.prefs().classesGlobalDialog);
        }

        /**
         * @param {RefElement} _el event source element
         * @param {KeyboardEvent} event event instance if DOM event
         */
        handleKeydown(_el, event) {
            if (!event) {
                return;
            }

            switch (event.keyCode) {
                case keyCode.SPACE:
                case keyCode.ENTER:
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.target && 'thumbnailsArrow' in event.target.dataset) {
                        break;
                    }
                    this.zoom();
                    break;
                default:
                    break;
            }
        }
    }

    return ProductImages;
}
