// TODO: JSDoc for all methods
// TODO: freeze loaded sections during update with aria-busy
// TODO: investigate proper implementation of aria-live region for updated sections
// TODO: keep track that focus stays on the same place during PLP update

import { submitFormJson } from 'widgets/toolbox/ajax';
import { showErrorLayout } from 'widgets/toolbox/util';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputSelect').default>>} inputSelect
 */

/**
 * @description Base ProductDetail implementation
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof ProductDetail} Product Detail class
 */
export default function (Widget) {
    /**
     * @class ProductDetail
     * @augments Widget
     * @classdesc Represents ProductDetail component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Rerender PDP and Quick View
     * 3. Handle product attributes change
     * 4. Handle add to cart event
     * @property {boolean} [data-ready-to-order=false] - ready to order flag
     * @property {string} data-text-select-options - select option text
     * @property {string} data-text-stock-limit - stock limit text
     * @property {string} data-out-of-stock-label - out of stock label
     * @property {number} data-selected-quantity - selected quantity
     * @category widgets
     * @subcategory product
     * @example
     * cartridges/app_storefront_widgets/cartridge/templates/default/product/productDetails.isml
     *
     * <main
     *     role="main"
     *     class="l-pdp-main"
     *     data-widget="productDetail"
     *     data-ready-to-order="${product.readyToOrder}"
     *     data-text-network-error="${Resource.msg('error.alert.network', 'product', null)}"
     *     data-text-select-options="${Resource.msg('error.alert.select.options', 'product', null)}"
     *     data-text-stock-limit="${Resource.msg('error.alert.stock.limit', 'product', null)}"
     *     data-out-of-stock-label="${Resource.msg('label.outofstock', 'common', null)}"
     *     data-add-to-cart-label="${Resource.msg('button.addtocart', 'common', null)}"
     *     data-selected-quantity="${product.selectedQuantity}"
     *     data-analytics="${JSON.stringify(product.gtmInfo)}"
     *     data-pid="${product.id}"
     *     data-tau-product-id="${product.id}"
     *     data-add-to-wishlist-hide-texts="false"
     *     data-show-minicart-on-product-add.md.lg.xl="true"
     *     data-show-message-on-product-add="true"
     *     data-text-added-to-wishlist="${Resource.msg('button.added.to.wishlist', 'wishlist', null)}"
     *     data-accessibility-alerts='{
     *         "quantitychanged": "${Resource.msg('alert.quantitychanged', 'product', null)}",
     *         "variationselected": "${Resource.msg('alert.variationselected', 'product', null)}",
     *         "addedtocart": "${Resource.msg('alert.addedtocart', 'product', null)}",
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}"
     *     }'
     * >
     *     PDP content
     * </main>
     */
    class ProductDetail extends Widget {
        prefs() {
            return {
                addToCartMsg: 'addToCartMsg',
                disableHistory: false,
                descriptions: 'descriptions',
                readyToOrder: false,
                selectedQuantity: 0,
                textSelectOptions: '',
                textStockLimit: '',
                outOfStockLabel: '',
                updateLabel: '',
                update: false,
                accessibilityAlerts: {},
                ...super.prefs()
            };
        }

        /**
         * @description updateProduct handler
         * @param {object} updateBtn Response object
         * @returns {void}
         */
        updateProduct(updateBtn) {
            submitFormJson(
                updateBtn.ref('self').attr('data-update-url'),
                {
                    pid: this.currentProductID || updateBtn.prefs().pid,
                    quantity: this.selectedQuantity || updateBtn.ref('self').attr('data-selected-quantity') || 1,
                    uuid: updateBtn.ref('self').attr('data-uuid')
                }
            ).then(this.afterUpdateProduct.bind(this))
                .finally(() => {
                    this.emit('updated');
                });
        }

        /**
         * @description afterUpdateProduct handler
         * @param {object} response Response object
         * @emits "product.updated"
         * @returns {void}
         */
        afterUpdateProduct(response) {
            /**
             * @description Event to notify concerned widgets, that product was updated
             * @event "product.updated"
             */
            this.eventBus().emit('product.updated', response);
        }

        /**
         * @description Update Product View
         * @param {any} product productModel
         * @returns {void}
         */
        updateProductView(product) {
            this.currentProductID = product.id;
            this.currentGtmInfo = product.gtmInfo;
            this.selectedQuantity = product.selectedQuantity;
            this.uuid = product.uuid;
            this.readyToOrder = product.readyToOrder;
            this.stock = product.availability && product.availability.stock ? product.availability.stock : null;

            this.setGtmInfo(product);

            this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                addToCartMsg.hide();
            });
            this.renderVariationAttributes(product);
            this.renderQuantities(product);
            this.renderPrice(product);
            this.renderAvailability(product);
            this.renderImages(product);
            this.renderName(product);
            this.renderPromotions(product);
            this.renderProductDescriptions(product);
            this.updateSocialLinks(product);
            this.updateViewFullProductURL(product);
        }

        /**
         * @description Render product view full product url
         * @param {object} product Product object
         * @returns {void}
         */
        updateViewFullProductURL(product) {
            if (product.selectedProductUrl) {
                this.has('viewFullProductURL', (el) => {
                    el.attr('href', product.selectedProductUrl);
                });
            }
        }

        /**
         * @description Set GTM info
         * @param {object} product Product object
         * @returns {void}
         */
        setGtmInfo(product) {
            this.currentGtmInfo = product.gtmInfo;

            if (this.currentGtmInfo && product.selectedQuantity) {
                this.currentGtmInfo.quantity = product.selectedQuantity;
            }
        }

        /**
         * @description Render product attributes
         * @param {object} product Product object
         * @returns {void}
         */
        renderVariationAttributes(product) {
            if (product.variationAttributes && product.variationAttributes.length) {
                product.variationAttributes.forEach(variationAttribute => {
                    variationAttribute.values = variationAttribute.values.map(value => {
                        return {
                            ...value,
                            selected: value.selected,
                            disabled: !value.selectable,
                            selectable: value.selectable ? 'selectable' : ''
                        };
                    });
                    this.getById('attr-' + variationAttribute.attributeId, (attribute) => {
                        attribute.render('template', {
                            attr: variationAttribute
                        });
                    });
                });
            }
        }

        /**
         * @description Executes when user clicks on product details link.
         * Usually used by analytics etc.
         * @emits "detail.product.link.click"
         * @param {refElement} link - clicked product tile link
         * @returns {void}
         */
        onProductLinkClick(link) {
            this.eventBus().emit('detail.product.link.click', link);
        }

        /**
         * @description Render product quantity
         * @param {object} product Product object
         * @returns {void}
         */
        renderQuantities(product) {
            if (product.quantities) {
                this.getById('quantity-1', (quantity) => {
                    quantity.render('template', {
                        attr: {
                            values: product.quantities.map(qty => {
                                return {
                                    selected: qty.selected ? 'selected' : '',
                                    url: qty.url,
                                    value: qty.value
                                };
                            })
                        },
                        isOutOfStock: product.availability && product.availability.isOutOfStock
                    });
                });
            }
        }

        /**
         * @description Render product name
         * @param {object} product Product object
         * @returns {void}
         */
        renderName(product) {
            if (product.productName) {
                this.ref('name').setText(product.productName);
            }
        }

        /**
         * @description Render product promotion
         * @param {object} product Product object
         * @returns {void}
         */
        renderPromotions(product) {
            this.getById('promotions', (promotions) => {
                promotions.render('template', {
                    promotions: product.promotions || []
                }, promotions.ref('container'));
            });
        }

        /**
         * @description Render product price
         * @param {object} product Product object
         * @returns {void}
         */
        renderPrice(product) {
            if (product.price && product.price.html) {
                this.getById('priceBlock', (priceBlock) => {
                    priceBlock.render('template', [], undefined, product.price.html);
                });
            }
        }

        /**
         * @description Render product availability
         * @param {object} product Product object
         * @returns {void}
         */
        renderAvailability(product) {
            let message = '';
            let availabilityClass = '';
            const productAvailabilityMsg = this.ref('productAvailabilityMsg');

            if (product.availability && product.availability.messages && product.readyToOrder) {
                message = product.availability.messages.join('');
                availabilityClass = product.availability.class;
            }

            if (product.availability.isReachedLimit) {
                message = product.availability.inStockMsg.join('');
            }

            if (productAvailabilityMsg) {
                productAvailabilityMsg.hide();
            }

            this.getById('availability', (availabilityLabel) => {
                availabilityLabel.render('template', {
                    message: message,
                    class: availabilityClass
                }, availabilityLabel.ref('container'));
            });
        }

        /**
         * @description Render product images
         * @param {object} product Product object
         * @returns {void}
         */
        renderImages(product) {
            this.getById('productImages', (productImages) => {
                productImages.renderImages(product);
            });
        }

        /**
         * @description Render product description
         * @param {object} product Product object
         * @returns {void}
         */
        renderProductDescriptions(product) {
            this.getById(this.prefs().descriptions, element => {
                element.render('template', product, element.ref('container')).then(() => element.init());
            });
        }

        /**
         * @description Update social links
         * @param {object} product Product object
         * @returns {void}
         */
        updateSocialLinks(product) {
            if (product.socialLinks !== undefined) {
                Object.keys(product.socialLinks).forEach((socialKey) => {
                    this.has('social-link-' + socialKey, link => {
                        link.attr('href', product.socialLinks[socialKey]);
                    });
                });
            }
        }

        /**
         * @description widget triggered event
         * Saves `selectWidget.id` into a property for further global notifications etc.
         * Later, when triggering event, `selectWidget.id` will be analised in order to send with event correct data.
         * @param {inputSelect} selectWidget widget
         * @returns {void}
         */
        changeAttribute(selectWidget) {
            this.changeAttributeID = selectWidget.id;
            const selected = selectWidget.getSelectedOptions();

            if (!selected || selected.data('attrIsSelected')) {
                return;
            }

            if (selected) {
                submitFormJson(selected.data('attrUrl'), undefined, 'GET', true)
                    .then(this.afterChangeAttribute.bind(this))
                    .catch(e => {
                        showErrorLayout(e);
                    });
            }
        }

        /**
         * @description After change attrigute handler
         * @param {object} response response object
         * @returns {void}
         */
        afterChangeAttribute(response) {
            if (response && response.product) {
                if (!this.prefs().disableHistory) {
                    this.updateHistoryState(response.product);
                }

                this.updateProductView(response.product);
                this.triggerChangeAttributeEvent();
            }
        }

        /**
         * @description Triggers global event after `afterChangeAttribute` method executed.
         * Puts localised global alert event description
         * @returns {void}
         */
        triggerChangeAttributeEvent() {
            if (!this.changeAttributeID) {
                return;
            }

            let accessibilityAlert = '';
            if (this.changeAttributeID.indexOf('quantity-') === 0) {
                accessibilityAlert = this.prefs().accessibilityAlerts.quantitychanged;
            } else if (this.changeAttributeID.indexOf('attr-') === 0) {
                accessibilityAlert = this.prefs().accessibilityAlerts.variationselected;
            }
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });
            this.changeAttributeID = undefined;
        }

        /**
         * @description Update History State
         * @param {object} product product object
         * @returns {void}
         */
        updateHistoryState(product) {
            window.history.replaceState(undefined, '', product.selectedProductUrl);
        }
    }

    return ProductDetail;
}
