// TODO: JSDoc for all methods
// TODO: should be implemented as https://www.w3.org/TR/wai-aria-practices/#Listbox
// single select controls group (single tab stop element) with activation on click/space/enter
import { RefElement } from 'widgets/toolbox/RefElement';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Base ColorSwatch implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ColorSwatch} Color Swatch class
 */
export default function (Widget) {
    /**
     * @class ColorSwatch
     * @augments Widget
     */
    class ColorSwatch extends Widget {
        selectColor(option) {
            this.selectedOption = option;
            this.emit('change', this);
        }

        getSelectedOptions() {
            if (this.selectedOption) {
                return this.selectedOption;
            }
            return new RefElement([]);
        }
    }

    return ColorSwatch;
}
